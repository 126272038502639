import axios from '@axios'

export default {
  async getKinder(dosID) {
    try {
      const r = await axios.get('sproc/getKinder', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putKinder(data) {
    const d = JSON.parse(JSON.stringify(data))
    try {
      for (let i = 0; i < d.length; i += 1) {
        d[i].Wohnanteil = (d[i].Wohnanteil ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].ObhutElternID = d[i].ObhutElternID ? 2 : 1
        d[i].FZElternID = d[i].FZElternID ? 2 : 1
      }
      const r = await axios.get('sproc/getKindUpdate', {
        params: {
          data: JSON.stringify(d),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getKindDelete(dosID, kinID) {
    try {
      const r = await axios.get('sproc/getKindDelete', {
        params: {
          dosID,
          kinID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
}
