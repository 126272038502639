import axios from '@axios'

export default {
  async getEltern(dosID) {
    try {
      const r = await axios.get('eltern/getEltern', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },

  async putEltern(data) {
    try {
      const d = JSON.parse(JSON.stringify(data))
      d.HypoEinkommenEF = (d.HypoEinkommenEF ?? '0').toString().replaceAll(/[’']/g, '')
      d.HypoEinkommenEM = (d.HypoEinkommenEM ?? '0').toString().replaceAll(/[’']/g, '')
      const r = await axios.get('eltern/getElternUpdate', {
        params: {
          data: JSON.stringify(d),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getGemeinden(kantonID) {
    try {
      const r = await axios.get('sproc/getGemeinden', {
        params: {
          kantonID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
}
