<template>
  <div v-if="!data">Loading Please wait...
  </div>
  <div v-else>
    <validation-observer #default="{ invalid }">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <b-form-radio-group
                id="kidz"
                v-model="selKid"
                button-variant="outline-primary"
                :options="kidz"
                buttons
                name="rKidz"
                @change="changeKid"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="vorname"
              rules="required"
            >
              <b-form-group
                label="Vorname"
                label-for="Vorname"
              >
                <b-form-input
                  id="Vorname"
                  v-model="data[idxKid].Vorname"
                  :state="errors.length > 0 ? false:null"
                  @blur="updVname"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Vorname Frau...' : 'Vorname Frau...' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-group
                label="Name"
                label-for="Name"
              >
                <b-form-input
                  id="Name"
                  v-model="data[idxKid].Name"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Vorname Frau...' : 'Vorname Frau...' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Geburtstag"
              rules="required"
            >
              <b-form-group
                label="Geburtstag"
                label-for="Geburtstag"
              >
                <flat-pickr
                  v-model="data[idxKid].Geburtstag"
                  :config="config"
                  class="form-control"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Date de naissance...' : 'Geburtstag...' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="{ errors }"
              name="hoKind"
              rules="required"
            >
              <b-form-group
                label="Heimatort"
                label-for="Heimatort"
              >
                <b-form-input
                  id="hoKind"
                  v-model="data[idxKid].Heimatort"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Heimatort Kind eingeben!' : 'Heimatort Kind eingeben!' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="{ errors }"
              name="lKind"
              rules="required"
            >
              <b-form-group
                label="Land"
                label-for="Land"
              >
                <b-form-input
                  id="lKind"
                  v-model="data[idxKid].Land"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Land/Nationalität Kind eingeben!' : 'Land/Nationalität Kind eingeben!' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row v-if="data[idxKid].Art === 2">
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Zivilstandsamt"
              label-for="Heimatort"
            >
              <b-form-input
                id="zsamt"
                v-model="data[idxKid].Zivilstandsamt"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group
              :label="'Datum Anerkennung ' + data[idxKid].Vorname + ' von ' +  $root.VNameMann"
              label-for="Anerkennug"
            >
              <flat-pickr
                v-model="data[idxKid].Anerkannt"
                :config="config"
                class="form-control"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col v-show="dos.GeteilteObhut" cols="12" md="4">
            <b-form-group
              :label="'Wohnsitz ' + $root.VNameFrau"
              label-for="ObhutM"
            >
              <b-form-checkbox
                v-model="data[idxKid].ObhutElternID"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="'Familienzulage ' + $root.VNameFrau"
              label-for="FZM"
            >
              <b-form-checkbox
                v-model="data[idxKid].FZElternID"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12" md="4">
            <b-form-group
              label="Gute Gesundheit"
              label-for="Gesund"
            >
              <b-form-checkbox
                v-model="data[idxKid].IstGesund"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col> -->
        </b-row>

        <b-row>
          <b-col cols="12" md="4">
            <b-form-group
              label="Alter Ausbildungsende"
              label-for="AusbildungEnde"
              class="mb-4"
            >
              <vue-slider
                v-model="data[idxKid].AlterEndeErstAusbildung"
                :min="18"
                :max="25"
                :tooltip="'always'"
                :tooltip-placement="'right'"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group
              label="Einkommen Kind % Kostenbeitrag "
              label-for="ProzEink"
            >
              <vue-slider
                v-model="data[idxKid].EinkommenProz"
                :min="0"
                :max="66"
                :tooltip="'always'"
                :tooltip-placement="'right'"
              />
            </b-form-group>
          </b-col>

          <b-col v-show="dos.WohnKindPauschal === 0" cols="12" md="4">
            <b-form-group
              label="Wohnanteil %"
              label-for="Wohnanteil"
            >
              <vue-slider
                v-model="data[idxKid].WohnanteilProz"
                :min="10"
                :max="35"
                :tooltip="'always'"
                :tooltip-placement="'bottom'"
              />
            </b-form-group>
          </b-col>

          <b-col v-show="dos.WohnKindPauschal > 0" cols="12" md="4">
            <b-form-group
              label='Wohnanteil Pauschale'
              label-for="wohnKindlPauschale"
            >
              <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="data[idxKid].Wohnanteil"
                @blur="applyFormat($event)"
                @focus="removeFormat"
              />
            </b-form-group>
          </b-col>

        </b-row>

      </b-form>
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :disabled="invalid"
        @click="save_changes(true)"
      >
        Speichern
      </b-button>
      <b-button
        variant="secondary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :disabled="invalid"
        @click="fetchData()"
      >
        Abbrechen
      </b-button>
      <b-button
        v-if="isDel"
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="deleteKind"
      >
        Löschen
      </b-button>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BFormRadioGroup,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import flatPickr from 'vue-flatpickr-component'
import { German } from 'flatpickr/dist/l10n/de'
import { French } from 'flatpickr/dist/l10n/fr'
import db from '@/service/kind'
import dos from '@/service/dossier'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormRadioGroup,
    VueSlider,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      kidz: [],
      selKid: null,
      idxKid: 0,
      data: null,
      dos: null,
      isEditing: false,
      required,
      isDel: false,
      ok: false,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        dateFormat: 'Y-m-d', // Internes Format zu Speicherung...
        altInput: true,
        altFormat: 'd.m.Y', // Anzeigeformat...
        allowInput: true,
        locale: this.$g.user.Sprache === 'fr' ? French : German,
      },
    }
  },
  mounted() {
    this.$root.$refs.KIND = this
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    async fetchData() {
      const rd = await dos.getDossier(this.$root.currDosID)
      if (rd.data.length === 1) this.dos = rd.data[0]

      const r = await db.getKinder(this.$root.currDosID)
      if (r.data.length > 0) {
        this.isDel = this.$root.AnzahlKinder < r.data.length
        this.data = r.data
        this.kidz = []
        for (let i = 0; i < r.data.length; i += 1) {
          this.kidz.push(this.data[i].Vorname)
          this.data[i].ObhutElternID = this.data[i].ObhutElternID === 2
          this.data[i].FZElternID = this.data[i].FZElternID === 2
        }
        this.selKid = this.kidz[0]
        this.idxKid = 0
      }
    },
    async changeKid(checked) {
      for (let i = 0; i < this.kidz.length; i += 1) {
        if (this.kidz[i] === checked) {
          this.idxKid = i
          break
        }
      }
    },
    formatNumber(value) {
      if (!this.isEditing && typeof value === 'string') {
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
        return value
      }
      return false
    },
    applyFormat(event) {
      event.target.value = (event.target.value || '0').replace(/^0+(?!$)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
      this.data[this.idxKid].Wohnanteil = event.target.value
      this.isEditing = false
    },
    removeFormat(event) {
      this.isEditing = true
      event.target.value = event.target.value.replace(/[^\d]/g, '') // eslint-disable-line
      if (event.target.value === '0') event.target.value = '' // eslint-disable-line
    },
    updVname() {
      this.kidz.length = 0
      for (let i = 0; i < this.data.length; i += 1) this.kidz.push(this.data[i].Vorname)
      this.selKid = this.kidz[this.idxKid]
      // console.log(this.selKid)
      // console.log(this.kidz[this.idxKid])
      // this.kidz[this.idxKid] = this.data[this.idxKid].Vorname
      // console.log(this.data[this.idxKid].Vorname)
    },
    async save_changes(showToast) {
      // for (let i = 0; i < this.data.length; i += 1) {
      //   this.data[i].ObhutElternID = this.data[i].ObhutElternID ? 2 : 1
      //   this.data[i].FZElternID = this.data[i].FZElternID ? 2 : 1
      // }
      await db.putKinder(this.data)

      if (showToast) this.$root.showToast('Speichern', 'Kinderdaten erfolgreich gespeichert!')
    },
    async deleteKind() {
      if (await this.$root.msgBoxConfirm('Soll das Kind wirklich gelöscht werden?')) {
        await db.getKindDelete(this.$root.currDosID, this.data[this.idxKid].KinID)

        await this.fetchData()

        this.$root.showToast('Speichern', 'Kinderdaten erfolgreich gespeichert!')
      }
    },
  },
}
</script>

<style lang='scss'>
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
