<template>
  <div v-if="!data">Loading Please wait...
  </div>
  <div v-else>
    <!-- <validation-observer ref="observe" #default="{ invalid }"> -->
    <validation-observer #default="{ invalid }">
      <b-form>
        <b-row>
          <b-col  cols="12" md="4"/>
          <b-col
            cols="12"
            md="4"
            style="fontWeight:bold"
            class="mb-1"
          >
            {{ $root.VNameFrau }}
          </b-col>
          <b-col
            cols="12"
            md="4"
            style="fontWeight:bold"
          >
            {{ $root.VNameMann }}
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="8">
            <validation-provider
              #default="{ errors }"
              name="vNameFrau"
              rules="required"
            >
              <b-form-group
                label="Vorname"
                label-for="Vorname"
                label-cols-md="6"
              >
                <b-form-input
                  id="VNFrau"
                  v-model="data.MVorname"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Name Frau...' : 'Name Frau...' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="vNameMann"
              rules="required"
            >
              <b-form-input
                id="VNMann"
                v-model="data.VVorname"
                :state="errors.length > 0 ? false:null"
              />
              <small
                v-show="errors.length"
                class="text-danger"
              >{{ $g.browserLocale === 'fr' ? 'Vorname Mann...' : 'Vorname Mann...' }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="8" >
            <validation-provider
              #default="{ errors }"
              name="ameFrau"
              rules="required"
            >
              <b-form-group
                label="Name"
                label-for="Name"
                label-cols-md="6"
              >
                <b-form-input
                  id="NFrau"
                  v-model="data.MName"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Name Frau...' : 'Name Frau...' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="nameMann"
              rules="required"
            >
              <b-form-input
                id="NMann"
                v-model="data.VName"
                :state="errors.length > 0 ? false:null"
              />
              <small
                v-show="errors.length"
                class="text-danger"
              >{{ $g.browserLocale === 'fr' ? 'Vorname Mann...' : 'Vorname Mann...' }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="8">
            <b-form-group
              label="Geburtstag"
              label-for="Geburtstag"
              label-cols-md="6"
            >
              <flat-pickr
                v-model="data.MGeburtstag"
                :config="config"
                class="form-control"
                @on-change="setPensionM"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <flat-pickr
              v-model="data.VGeburtstag"
              :config="config"
              class="form-control"
              @on-change="setPensionV"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="8">
            <validation-provider
              #default="{ errors }"
              name="hoFrau"
              rules="required"
            >
              <b-form-group
                label="Heimatort"
                label-for="Heimatort"
                label-cols-md="6"
              >
                <b-form-input
                  id="hoFrau"
                  v-model="data.MHeimatort"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Heimatort Frau eingeben!' : 'Heimatort Frau eingeben!' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="hoMann"
              rules="required"
            >
              <b-form-input
                id="hoMann"
                v-model="data.VHeimatort"
                :state="errors.length > 0 ? false:null"
              />
              <small
                v-show="errors.length"
                class="text-danger"
              >{{ $g.browserLocale === 'fr' ? 'Heimatort Mann eingeben!' : 'Heimatort Mann eingeben!' }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="8">
            <validation-provider
              #default="{ errors }"
              name="lFrau"
              rules="required"
            >
              <b-form-group
                label="Land/Nationalität"
                label-for="Land"
                label-cols-md="6"
              >
                <b-form-input
                  id="lFrau"
                  v-model="data.MLand"
                  :state="errors.length > 0 ? false:null"
                />
                <small
                  v-show="errors.length"
                  class="text-danger"
                >{{ $g.browserLocale === 'fr' ? 'Land/Nationalität Frau eingeben!' : 'Land/Nationalität Frau eingeben!' }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="lMann"
              rules="required"
            >
              <b-form-input
                id="lMann"
                v-model="data.VLand"
                :state="errors.length > 0 ? false:null"
              />
              <small
                v-show="errors.length"
                class="text-danger"
              >{{ $g.browserLocale === 'fr' ? 'Land/Nationalität Mann eingeben!' : 'Land/Nationalität Mann eingeben!' }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row v-show="doss.IstSteuer">
          <b-col cols="12" md="8">
            <b-form-group
              label="Konfession"
              label-for="Konfession"
              label-cols-md="6"
            >
              <v-select
                v-model="data.MKonfession"
                :options="konf"
                :reduce="val => val.value"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="data.VKonfession"
              :options="konf"
              :reduce="val => val.value"
              :clearable="false"
            />
          </b-col>
        </b-row>

        <b-row v-show="doss.IstSteuer">
          <b-col cols="12" md="8">
            <b-form-group
              label="Wohnkanton"
              label-for="Wohnkanton"
              label-cols-md="6"
            >
              <v-select
                v-model="data.MWohnKantonID"
                :options="kanton"
                :reduce="val => val.value"
                :clearable="false"
                @input="getGemeindeFrau(true)"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="data.VWohnKantonID"
              :options="kanton"
              :reduce="val => val.value"
              :clearable="false"
              @input="getGemeindeMann(true)"
            />
          </b-col>
        </b-row>

        <b-row v-show="doss.IstSteuer">
          <b-col cols="12" md="8">
            <b-form-group
              label="Gemeinde"
              label-for="Gemeinde"
              label-cols-md="6"
            >
              <v-select
                v-model="data.MGemeindeID"
                :options="gemeindeFrau"
                :reduce="val => val.value"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="data.VGemeindeID"
              :options="gemeindeMann"
              :reduce="val => val.value"
              :clearable="false"
            />
          </b-col>
        </b-row>

        <b-row v-show="doss.IstSteuer">
          <b-col cols="12" md="8">
            <b-form-group
              label="Arbeitskanton"
              label-for="Arbeitskanton"
              label-cols-md="6"
            >
              <v-select
                v-model="data.MArbeitKantonID"
                :options="kanton"
                :reduce="val => val.value"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="data.VArbeitKantonID"
              :options="kanton"
              :reduce="val => val.value"
              :clearable="false"
            />
          </b-col>
        </b-row>

        <b-row v-show="doss.MitVA">
          <b-col cols="12" md="8" >
            <b-form-group
              label="AHV-Nr."
              label-for="AHVNr"
              label-cols-md="6"
            >
              <b-form-input v-facade="'###.####.####.##'"
                v-model="data.MAHVNr"
                placeholder="123.1234.1234.12"
              />
              <!-- <b-form-input v-mask="'###.####.####.##'"
                v-model="data.MAHVNr"
                placeholder="123.1234.1234.12"
              /> -->
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-input v-facade="'###.####.####.##'"
              v-model="data.VAHVNr"
              placeholder="123.1234.1234.12"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="8">
            <b-form-group label="Arbeitspensum in %, ggf. Arbeitsaufnahme ab" label-for="Arbeitspensum" label-cols-md="6">
              <b-row>
              <b-col cols="6" md="6">
                <vue-slider
                  v-model="data.PensumEF"
                  :min="0"
                  :max="100"
                  :tooltip="'always'"
                  :tooltip-placement="'bottom'"
                />
              </b-col>
              <b-col cols="6" md="6">
                <flat-pickr v-show="data.PensumEF < 100"
                v-model="data.PensumAbEF"
                :config="config"
                class="form-control"
                />
              </b-col>
            </b-row>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="2">
            <vue-slider
              v-model="data.PensumEM"
              :min="0"
              :max="100"
              :tooltip="'always'"
              :tooltip-placement="'bottom'"
            />
          </b-col>
          <b-col v-show="data.PensumEM < 100" cols="12" md="2">
            <flat-pickr
              v-model="data.PensumAbEM"
              :config="config"
              class="form-control"
            />
          </b-col>
        </b-row>

        <b-row v-show="data.PensumEF < 100 || data.PensumEM < 100">
          <b-col cols="12" md="8">
            <b-form-group
              label="Hypothetisches Einkommen bei 100% Pensum"
              label-for="HypoEinkommen"
              label-cols-md="6"
            >
            <b-form-input v-show="data.PensumEF < 100" v-facade="'#######'" :formatter="formatNumber" class="text-right"
              v-model="data.HypoEinkommenEF"
              @blur="applyFormat($event, 'data.HypoEinkommenEF')"
              @focus="removeFormat"
            />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
          <b-form-input v-show="data.PensumEM < 100" v-facade="'#######'" :formatter="formatNumber" class="text-right"
              v-model="data.HypoEinkommenEM"
              @blur="applyFormat($event, 'data.HypoEinkommenEM')"
              @focus="removeFormat"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="8">
            <b-form-group
              label="Arbeitsweg fix (bspw. Abo)"
              label-for="arbeitswegFix ef"
              label-cols-md="6"
            >
              <b-form-checkbox
                v-model="data.ArbeitswegFixEF"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-checkbox
              v-model="data.ArbeitswegFixEM"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-col>
        </b-row>

        <b-row v-show="doss.Art !== 2">
          <b-col cols="12" md="8">
            <b-form-group
              label="Pensionierung im Jahr"
              label-for="PenImJahr"
              label-cols-md="6"
            >
              <vue-slider
                v-model="data.MPension"
                :min="pensionM - 15"
                :max="pensionM + 15"
                :tooltip="'always'"
                :tooltip-placement="'right'"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4"
          >
            <vue-slider
              v-model="data.VPension"
              :min="pensionV - 15"
              :max="pensionV + 15"
              :tooltip="'always'"
              :tooltip-placement="'right'"
            />
          </b-col>
        </b-row>

        <b-row v-show="doss.Art !== 2">
          <b-col cols="12" md="8">
            <b-form-group
              label="IV-Rente Prozent"
              label-for="IVRenteProz"
              label-cols-md="6"
            >
              <vue-slider
                v-model="data.MIVRenteProz"
                :min="0"
                :max="100"
                :tooltip="'always'"
                :tooltip-placement="'right'"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <vue-slider
              v-model="data.VIVRenteProz"
              :min="0"
              :max="100"
              :tooltip="'always'"
              :tooltip-placement="'right'"
            />
          </b-col>
        </b-row>

        <b-row v-show="doss.Art !== 2">
          <b-col cols="12" md="8">
            <b-form-group
              label="Bezug BVG-Rente"
              label-for="BVGRente"
              label-cols-md="6"
            >
              <b-form-checkbox
                v-model="data.MIstBVGRente"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-checkbox
              v-model="data.VIstBVGRente"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="8">
            <b-form-group
              label="Wohngemeinschaft"
              label-for="Wohngemeinschaft"
              label-cols-md="6"
            >
              <b-form-checkbox
                v-model="data.MWohngemeinschaft"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-checkbox
              v-model="data.VWohngemeinschaft"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="8">
            <b-form-group
              label="Hausfrau / -mann"
              label-for="HausfrauMann"
              label-cols-md="6"
            >
              <b-form-checkbox
                v-model="data.MIstHausGatte"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-checkbox
              v-model="data.VIstHausGatte"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-col>
        </b-row>

        <b-row v-show="1 === 2">
          <b-col cols="12" md="8">
            <b-form-group
              label="Gute Gesundheit"
              label-for="Gesundheit"
              label-cols-md="6"
            >
              <b-form-checkbox
                v-model="data.MIstGesundheitGut"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-checkbox
              v-model="data.VIstGesundheitGut"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="8">
            <b-form-group
              label="Berufsabschluss / Studium"
              label-for="Ausbildung"
              label-cols-md="6"
            >
              <b-form-checkbox
                v-model="data.MHatAusbildung"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-checkbox
              v-model="data.VHatAusbildung"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-col>
        </b-row>

        <b-row v-show="1 === 2">
          <b-col cols="12" md="8">
            <b-form-group
              label="Entwurzelt aus fremder Kultur"
              label-for="Entwurzelung"
              label-cols-md="6"
            >
              <b-form-checkbox
                v-model="data.MIstEntwurzelt"
                class="custom-control-primary"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-checkbox
              v-model="data.VIstEntwurzelt"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="8">
            <b-form-group
              label="Anderweitige familienrechtliche Verpflichtungen"
              label-for="famverpfl"
              label-cols-md="6"
            >
              <b-form-textarea
                v-model="data.MFamPflicht"
                rows="2"
                max-rows="3"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-textarea
              v-model="data.VFamPflicht"
              rows="2"
              max-rows="3"
            />
          </b-col>
        </b-row>

      </b-form>
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :disabled="invalid"
        @click="save_changes(true)"
      >
        Speichern
      </b-button>
      <b-button
        variant="secondary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :disabled="invalid"
        @click="fetchData()"
      >
        Abbrechen
      </b-button>
    </validation-observer>
  </div>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueSlider from 'vue-slider-component'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import { German } from 'flatpickr/dist/l10n/de'
import { French } from 'flatpickr/dist/l10n/fr'
import db from '@/service/paar'
import dos from '@/service/dossier'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormTextarea,
    flatPickr,
    vSelect,
    VueSlider,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    const gemeindeFrau = []
    const gemeindeMann = []

    const pensionM = 0
    const pensionV = 0

    const konf = [
      { label: this.$g.user.Sprache === 'fr' ? 'Réformé' : 'Reformiert', value: 1 },
      { label: this.$g.user.Sprache === 'fr' ? 'Catholique' : 'Katholisch', value: 2 },
      { label: this.$g.user.Sprache === 'fr' ? 'Autre' : 'Andere', value: 3 },
    ]

    const kanton = [
      { value: '-', label: this.$g.user.Sprache === 'fr' ? '-' : '-' },
      { value: 'AG', label: this.$g.user.Sprache === 'fr' ? 'Argovie' : 'Aargau' },
      { value: 'AR', label: this.$g.user.Sprache === 'fr' ? 'Appenzell Rhodes-Extérieures' : 'Appenzell Ausserrhoden' },
      { value: 'AI', label: this.$g.user.Sprache === 'fr' ? 'Appenzell Rhodes-Intérieures' : 'Appenzell Innerrhode' },
      { value: 'BL', label: this.$g.user.Sprache === 'fr' ? 'Bâle-Campagne' : 'Basel Landschaft' },
      { value: 'BS', label: this.$g.user.Sprache === 'fr' ? 'Bâle-Ville' : 'Basel Stadt' },
      { value: 'BE', label: this.$g.user.Sprache === 'fr' ? 'Berne' : 'Bern' },
      { value: 'FR', label: this.$g.user.Sprache === 'fr' ? 'Fribourg' : 'Freiburg' },
      { value: 'GE', label: this.$g.user.Sprache === 'fr' ? 'Genève' : 'Genf' },
      { value: 'GL', label: this.$g.user.Sprache === 'fr' ? 'Glaris' : 'Glarus' },
      { value: 'GR', label: this.$g.user.Sprache === 'fr' ? 'Grisons' : 'Graubünden' },
      { value: 'JU', label: this.$g.user.Sprache === 'fr' ? 'Jura' : 'Jura' },
      { value: 'LU', label: this.$g.user.Sprache === 'fr' ? 'Lucerne' : 'Luzern' },
      { value: 'NE', label: this.$g.user.Sprache === 'fr' ? 'Neuchâtel' : 'Neuenburg' },
      { value: 'NW', label: this.$g.user.Sprache === 'fr' ? 'Nidwald' : 'Nidwalden' },
      { value: 'OW', label: this.$g.user.Sprache === 'fr' ? 'Obwald' : 'Obwalden' },
      { value: 'SG', label: this.$g.user.Sprache === 'fr' ? 'Saint-Gall' : 'St. Gallen' },
      { value: 'SH', label: this.$g.user.Sprache === 'fr' ? 'Schaffhouse' : 'Schaffhausen' },
      { value: 'SZ', label: this.$g.user.Sprache === 'fr' ? 'Schwyz' : 'Schwyz' },
      { value: 'SO', label: this.$g.user.Sprache === 'fr' ? 'Soleure' : 'Solothurn' },
      { value: 'TI', label: this.$g.user.Sprache === 'fr' ? 'Tessin' : 'Tessin' },
      { value: 'TG', label: this.$g.user.Sprache === 'fr' ? 'Thurgovie' : 'Thurgau' },
      { value: 'UR', label: this.$g.user.Sprache === 'fr' ? 'Uri' : 'Uri' },
      { value: 'VD', label: this.$g.user.Sprache === 'fr' ? 'Vaud' : 'Waadt' },
      { value: 'VS', label: this.$g.user.Sprache === 'fr' ? 'Valais' : 'Wallis' },
      { value: 'ZH', label: this.$g.user.Sprache === 'fr' ? 'Zurich' : 'Zürich' },
      { value: 'ZG', label: this.$g.user.Sprache === 'fr' ? 'Zoug' : 'Zug' },
    ]
    return {
      gemeindeFrau,
      gemeindeMann,
      pensionM,
      pensionV,
      konf,
      kanton,
      data: null,
      doss: null,
      isEditing: false,
      HypoEinkommenEF: null,
      required,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        dateFormat: 'Y-m-d', // Internes Format zu Speicherung...
        altInput: true,
        altFormat: 'd.m.Y', // Anzeigeformat...
        allowInput: true,
        locale: this.$g.user.Sprache === 'fr' ? French : German,
      },
    }
  },
  mounted() {
    this.$root.$refs.PAAR = this
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    // formatMoney(value, event) {
    // formatMoney(event, vmodel) {
    //   event.target.value = event.target.unmaskedValue.replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
    //   const parts = vmodel.split('.')
    //   if (parts.length === 2) this[parts[0]][parts[1]] = event.target.value // eslint-disable-line
    //   else this[parts[0]] = event.target.value // eslint-disable-line
    // },
    async fetchData() {
      const d = await dos.getDossier(this.$root.currDosID)
      if (d.data.length > 0) this.doss = d.data[0]

      this.data = null
      const r = await db.getEltern(this.$root.currDosID)
      if (r.data.length > 0) {
        this.data = r.data[0]
        let m = moment(this.data.MGeburtstag)
        this.pensionM = m.add(65, 'years').year()
        m = moment(this.data.VGeburtstag)
        this.pensionV = m.add(65, 'years').year()
        this.HypoEinkommenEF = this.data.HypoEinkommenEF

        if (this.$refs.observe !== undefined) this.$refs.observe.validate()

        await this.getGemeindeFrau(false)
        await this.getGemeindeMann(false)
      }
    },
    formatNumber(value) {
      if (!this.isEditing && typeof value === 'string') {
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
        return value
      }
      return false
    },
    applyFormat(event, value) {
      event.target.value = (event.target.value || '0').replace(/^0+(?!$)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
      const parts = value.split('.')
      if (parts.length === 2) this[parts[0]][parts[1]] = event.target.value // eslint-disable-line
      else this[parts[0]] = event.target.value // eslint-disable-line
      this.isEditing = false
    },
    removeFormat(event) {
      this.isEditing = true
      event.target.value = event.target.value.replace(/[^\d]/g, '') // eslint-disable-line
      if (event.target.value === '0') event.target.value = '' // eslint-disable-line
    },
    async setPensionM() {
      const m = moment(this.data.MGeburtstag)
      this.data.MPension = m.add(65, 'years').year()
    },
    async setPensionV() {
      const m = moment(this.data.VGeburtstag)
      this.data.VPension = m.add(65, 'years').year()
    },
    async getGemeindeFrau(kantonChanged) {
      const r = await db.getGemeinden(this.data.MWohnKantonID)
      if (r.data.length > 0) {
        this.gemeindeFrau = r.data
        if (kantonChanged) this.data.MGemeindeID = r.data[0].value
      }
    },
    async getGemeindeMann(kantonChanged) {
      const r = await db.getGemeinden(this.data.VWohnKantonID)
      if (r.data.length > 0) {
        this.gemeindeMann = r.data
        if (kantonChanged) this.data.VGemeindeID = r.data[0].value
      }
    },
    async save_changes(showToast) {
      this.$root.VNameFrau = this.data.MVorname
      this.$root.VNameMann = this.data.VVorname
      if (this.data.PensumEF === 100) this.data.PensumAbEF = null
      if (this.data.PensumEM === 100) this.data.PensumAbEM = null
      await db.putEltern(this.data)

      if (showToast) this.$root.showToast('Speichern', 'Paar-Daten erfolgreich gespeichert!')
    },
  },
}
</script>

<style lang='scss'>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
