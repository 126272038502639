import axios from '@axios'

export default {
  async getFragebogen(dosID) {
    try {
      const r = await axios.get('dossiers/getFragebogen', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  // async putFragebogen(data) {
  //   const d = JSON.parse(JSON.stringify(data))
  //   try {
  //     d.AnspruchElternID = d.AnspruchElternID ? 2 : 1
  //     d.WerWohnungID = d.WerWohnungID ? 2 : 1
  //     d.WohnKindPauschal = (d.WohnKindPauschal ?? '0').toString().replaceAll(/[’']/g, '')
  //     const r = await axios.get('dossiers/getDossierUpdate', {
  //       params: {
  //         data: JSON.stringify(d),
  //       },
  //     })
  //     return r
  //   } catch (error) {
  //     // console.log(error)
  //     this.errored = true
  //   }
  //   return null
  // },
}
